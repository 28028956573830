.create-page .MuiCardContent-root {
    padding: 0;
}

.create-page .MuiPaper-root {
    background-color: transparent;
  }
  
.create-page .MuiPaper-elevation1 {
    box-shadow: none;
}
  
.create-page .MuiPaper-rounded {
    border-radius: 0;
}