.edit-page .MuiPaper-root {
  background-color: transparent;
}

.edit-page .MuiPaper-root:first-child .MuiCardContent-root {
  padding-top: 0;
}

.edit-page .MuiPaper-elevation1 {
  box-shadow: none;
}

.edit-page .MuiPaper-rounded {
  border-radius: 0;
}

.edit-page .MuiPaper-root:nth-child(2) > .MuiCardContent-root {
  box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%),
    0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
  background: #fff;
  border-radius: "4px";
}
